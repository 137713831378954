<template>
  <!-- 分区类别 -->
  <div>
    <!-- 左上角新增按钮 -->
    <div class="new-btn">
      <el-button type="danger" size="mini" @click="onAddSubarea"
        >新增</el-button
      >
    </div>
    <!-- 中间表格 -->
    <div>
      <el-table :data="data"  border>
        <el-table-column
          label="序号"
          prop="id"
          align="center"
        ></el-table-column>
        <el-table-column
          label="分区类别"
          prop="typeName"
          align="center"
        ></el-table-column>
        <el-table-column
          label="排序字段"
          prop="sort"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="btn">
              <div @click="increate(scope.$index, scope.row.id)">编辑</div>
              <div @click="deletes(scope.$index, scope.row.id)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: null,
      total: null,
      currentPage: 1,
      pageNumber: 20,
    };
  },
  created() {
    this.axios.get("/admin/certificateType/findAll", {}).then((res) => {
      if (res.data.code == 200) {
        this.data = res.data.data;
        this.total = res.data.data.total;
        this.currentPage = res.data.data.current;
      }
    });
  },
  methods: {
    // 编辑
    increate(index) {
      this.$router.push({
        path: "increased",
        query: { data: this.data[index] },
      });
    },
    // 新增
    onAddSubarea() {
      this.$router.push("addSubarea");
    },
    // 删除
    deletes(index, id) {
      console.log(index, id);
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .delete(`/admin/certificateType/delFirmType?ids=${id}`, {})
            .then((res) => {
              if (res.data.code == 200) {
                // 删除元素
                this.data.splice(index, 1);
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              } else {
                this.$message({
                  type: "errer",
                  message: "删除失败!",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-btn {
  padding: 20px;
}

.btn {
  display: flex;
  justify-content: center;
  div {
    margin: 0 3px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
</style>
